import React from 'react';
import GenericListView from '../../../components/shared/GenericListView';

function PoemList() {

  return (
    <div>
      <GenericListView url="/api/literature/poem" />
    </div>
  );
}

export default PoemList;
