import React from 'react';
import GenericListView from '../../../components/shared/GenericListView';

function CartoonList() {

  return (
    <div>
      <GenericListView url="/api/literature/cartoon" />
    </div>
  );
}

export default CartoonList;
