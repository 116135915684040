import React from 'react';
import ArticleListView from './ArticleListView';
import ArticleOfTheDay from './ArticleOfTheDay';
import Paper from '@mui/material/Paper';
import { useState, useEffect } from 'react';
import {fetchData, flattenContents, sortByCreatedDate, getTodaysIndex, capitalizeAndSpacify } from '../../helpers';
import useMediaQuery from '@mui/material/useMediaQuery';
import theme from './Theme';

function GenericListView({url}) {

  const [data, setData] = useState([]);
  const [contentOfTheDay, setContentOfTheDay] = useState(null);
  const [contentType, setContentType] = useState(null);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));

  useEffect(() => {
    fetchData(url).then((contents) => {
      setContentType(capitalizeAndSpacify(Object.keys(contents)[0]));
      const flattenedContents = flattenContents(contents);
      const sortedContents = sortByCreatedDate(flattenedContents).reverse();
      const sortedContentsLength = sortedContents.length;
      if (sortedContentsLength > 0) {
        setContentOfTheDay(sortedContents[getTodaysIndex(sortedContentsLength)]);
      }
      setData(sortedContents);
    }
  )}, [url]);

  return (
    <div>
      <ArticleOfTheDay data={contentOfTheDay} contentType={contentType} />
      <Paper elevation={0} style={{ padding: isMobile ? '0px 0px' : isTablet ? '0px 10px' : '0px 40px' }}>
        <ArticleListView data={data} contentType={contentType} />
      </Paper>
    </div>
  );
}

export default GenericListView;
