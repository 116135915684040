import React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Placeholder from '../../assets/images/placeholder.png';
import { capitalizeAndSpacify } from '../../helpers';
import { Link } from 'react-router-dom';
import useTheme from '@mui/material/styles/useTheme';
import useMediaQuery from '@mui/material/useMediaQuery';

const AllHighlights = (content) => {
  const item = content.data[0];
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return item && (
    <div>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ backgroundColor: "#f6f6f6" }}
      >
        <Grid item xs={12} md={5}>
          <img src={item.image} alt="placeholder" style={{
            width: "100%", maxHeight: isMobile ? "300px" : "400px",
            objectFit: "cover", objectPosition: "center"
          }} />
        </Grid>
        <Grid item xs={12} md={7} style={{ padding: isMobile ? "10px" : "40px" }}>
          <Typography variant="genreBold">{capitalizeAndSpacify(item.genre)}</Typography>
          <Typography variant="genre"> | {capitalizeAndSpacify(item.subgenre)}</Typography>
          <Typography variant="h1" style={{ marginTop: 8 }}>{item.title}</Typography>
          <Typography variant="summary">{item.summary}</Typography>
          <Link to={`/${item.subgenre}/${item.slug}`} style={{ textDecoration: 'none' }}>
            <Typography variant="extraInfo"
              sx={{
                '&:hover': {
                  textDecoration: 'underline',
                }
              }}
            >
              Read More
            </Typography>
          </Link>
          <br />
          <div style={{ marginTop: 16 }}>
            {item.is_translated &&
              <Typography variant="authorBold">
                Translated by {item.author}
              </Typography>
            }
            {item.is_translated &&
              <Typography variant="author">
                <br />Written by {item.original_author}
              </Typography>}

            {!item.is_translated &&
              <Typography variant="authorBold">
                Written by {item.author}
              </Typography>
            }
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default AllHighlights;
