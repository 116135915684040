import React from 'react';
import { useParams } from 'react-router-dom';
import GenericDetailView from '../../../components/shared/GenericDetailView';

function ComicDetail() {

    const { slug } = useParams();
        return (
            <GenericDetailView url={`/api/literature/comic/${slug}`} />
        );
}

export default ComicDetail;
