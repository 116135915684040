import React from 'react';
import GenericListView from '../../../components/shared/GenericListView';

function VisualList() {

  return (
    <div>
      <GenericListView url="/api/literature/visual" />
    </div>
  );
}

export default VisualList;
