import React from 'react';
import { useParams } from 'react-router-dom';
import GenericDetailView from '../../../components/shared/GenericDetailView';

function NovellaDetail() {

    const { slug } = useParams();
    return (
        <GenericDetailView url={`/api/literature/novella/${slug}`} />
    );
}

export default NovellaDetail;
