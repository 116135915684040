import React from 'react';
import GenericListView from '../../../components/shared/GenericListView';

function OpinionArticleList() {

  return (
    <div>
      <GenericListView url="/api/literature/opinion-article" />
    </div>
  );
}

export default OpinionArticleList;
