import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

const literatures = [
  {
    name: 'Fiction',
    link: '/fiction',
  },
  {
    name: 'Poetry',
    link: '/poetry',
  },
  {
    name: 'Analysis',
    link: '/analysis',
  },
  {
    name: 'Visual',
    link: '/visual',
  },
  {
    name: 'Fun',
    link: '/fun',
  },
];

function LiteratureAppBar() {
  return (
    <AppBar
      position="static"
      style={{
        padding: "0px 2%",
        maxHeight: 72,
      }}
    >
      <Toolbar disableGutters>
        {literatures.map((lit) => (
          <Button
            key={lit.name}
            sx={{ my: 2, color: 'white', display: 'block' }}
            href={lit.link}
          >
            <Typography variant="headerLit" color="secondary" style={{ padding: "0px 16px" }}>{lit.name}</Typography>
          </Button>
        ))}
      </Toolbar>
    </AppBar>
  );
}
export default LiteratureAppBar;
