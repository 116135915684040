import React from 'react';
import Typography from '@mui/material/Typography';

function Home() {
  const today = new Date();
  const year = today.getFullYear();
  return (
    <>
      <br />
      <br />
      <br />
      <br />
      <Typography variant="small" style={{ padding: "24px 64px" }}>©{year} KAIST CHRONICLES. ALL RIGHTS RESERVED</Typography>
    </>
  );
}

export default Home;
