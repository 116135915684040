import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import logo from '../../assets/images/logo.png';

const generals = [
  {
    name: 'About',
    link: '/about',
  },
  {
    name: 'News',
    link: '/news',
  },
  {
    name: 'Issues',
    link: '/issues',
  },
  {
    name: 'Contact',
    link: '/contact',
  },
];

function LiteratureAppBar() {
  return (
    <AppBar position="static" style={{ overflow: 'hidden', boxShadow: 'none' }}>
      <Toolbar
        disableGutters
        style={{
          backgroundColor: "white",
          padding: "20px 4%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <a href="/"><img src={logo} alt="logo" style={{ width: "300px" }} /></a>
        <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'flex' }}} style={{ justifyContent: 'flex-end' }}>
          {generals.map((gen) => (
            <Button
              key={gen.name}
              sx={{ my: 2, display: 'block' }}
              href={gen.link}
            >
              <Typography variant="small">{gen.name}</Typography>
            </Button>
          ))}
        </Box>
      </Toolbar>
    </AppBar>
  );
}
export default LiteratureAppBar;
