import React from 'react';
import {
  Box,
  Typography,
} from '@mui/material';
import { Link } from 'react-router-dom';

function ArticleOfTheDay({ data, contentType }) {
  return (
    data && contentType &&
    <Box style={{ backgroundColor: "#f6f6f6", padding: 56 }} textAlign="center">
      <Typography variant="genreBold">
        {contentType} Of the Day
      </Typography>
      <Typography variant="h1" style={{ marginTop: 16 }}>
        {data.title}
      </Typography>
      {data.is_translated &&
        <Typography variant="authorBold">
          Translated by {data.author}
        </Typography>
      }
      {data.is_translated &&
        <Typography variant="author">
          <br />Written by {data.original_author}
        </Typography>}

      {!data.is_translated &&
        <Typography variant="authorBold">
          Written by {data.author}
        </Typography>
      }
      <Typography variant="summary" style={{ marginTop: 24 }}>
        {data.summary}
      </Typography>
      <Link to={`/${data.subgenre}/${data.slug}`} style={{ textDecoration: 'none' }}>
        <Typography variant="extraInfo"
          sx={{
            '&:hover': {
              textDecoration: 'underline',
            }
          }}
        >
          Read More
        </Typography>
      </Link>
    </Box>
  )
}

export default ArticleOfTheDay;


