import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';

import './App.css';
import GeneralAppBar from './components/shared/GeneralAppBar';
import Footer from './components/shared/Footer';
import LiteratureAppBar from './components/shared/LiteratureAppBar';
import Home from './pages/Home';
import NotFound from './pages/NotFound';

import About from './pages/social/About';
import News from './pages/social/News';
import Issues from './pages/social/Issues';
import Contact from './pages/social/Contact';

import FictionList from './pages/literature/fiction/FictionList';
import PoetryList from './pages/literature/poetry/PoetryList';
import AnalysisList from './pages/literature/analysis/AnalysisList';
import VisualList from './pages/literature/visual/VisualList';
import FunList from './pages/literature/fun/FunList';

import NovellaList from './pages/literature/fiction/NovellaList';
import SerialList from './pages/literature/fiction/SerialList';
import DramaList from './pages/literature/fiction/DramaList';
import ShortStoryList from './pages/literature/fiction/ShortStoryList';
import PoemList from './pages/literature/poetry/PoemList';
import BookReviewList from './pages/literature/analysis/BookReviewList';
import OpinionArticleList from './pages/literature/analysis/OpinionArticleList';
import AuthorFeatureList from './pages/literature/analysis/AuthorFeatureList';
import CartoonList from './pages/literature/visual/CartoonList';
import ComicList from './pages/literature/visual/ComicList';
import JokeList from './pages/literature/fun/JokeList';
import MemeList from './pages/literature/fun/MemeList';
import PuzzleList from './pages/literature/fun/PuzzleList';
import CrosswordList from './pages/literature/fun/CrosswordList';

import NovellaDetail from './pages/literature/fiction/NovellaDetail';
import SerialDetail from './pages/literature/fiction/SerialDetail';
import DramaDetail from './pages/literature/fiction/DramaDetail';
import ShortStoryDetail from './pages/literature/fiction/ShortStoryDetail';
import PoemDetail from './pages/literature/poetry/PoemDetail';
import BookReviewDetail from './pages/literature/analysis/BookReviewDetail';
import OpinionArticleDetail from './pages/literature/analysis/OpinionArticleDetail';
import AuthorFeatureDetail from './pages/literature/analysis/AuthorFeatureDetail';
import CartoonDetail from './pages/literature/visual/CartoonDetail';
import ComicDetail from './pages/literature/visual/ComicDetail';
import JokeDetail from './pages/literature/fun/JokeDetail';
import MemeDetail from './pages/literature/fun/MemeDetail';
import PuzzleDetail from './pages/literature/fun/PuzzleDetail';
import CrosswordDetail from './pages/literature/fun/CrosswordDetail';
import MobileAppBar from './components/shared/MobileAppBar';

import theme from './components/shared/Theme';

const App = () => {
  const matchesLG = useMediaQuery(theme.breakpoints.up('lg'));
  const matchesXL = useMediaQuery(theme.breakpoints.up('xl'));
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Router>
      <ThemeProvider theme={theme}>
      <Box
          sx={{
            maxWidth: matchesXL ? `${theme.breakpoints.values.xl}px` : '100%', 
            margin: '0 auto',
            padding: matchesLG ? '0 4rem' : '0 0rem'
          }}
        >
          {isMobile && <MobileAppBar />}
          {!isMobile && 
          <>
          <GeneralAppBar />
          <LiteratureAppBar />
          </>
          }
          <Routes>
            <Route exact path="/" element={<Home/>}/>

            <Route exact path="/about" element={<About/>}/>
            <Route exact path="/news" element={<News/>}/>
            <Route exact path="/issues" element={<Issues/>}/>
            <Route exact path="/contact" element={<Contact/>}/>

            <Route exact path="/fiction" element={<FictionList/>}/>
            <Route exact path="/poetry" element={<PoetryList/>}/>
            <Route exact path="/analysis" element={<AnalysisList/>}/>
            <Route exact path="/visual" element={<VisualList/>}/>
            <Route exact path="/fun" element={<FunList/>}/>

            <Route exact path="/novella" element={<NovellaList/>}/>
            <Route exact path="/serial" element={<SerialList/>}/>
            <Route exact path="/drama" element={<DramaList/>}/>
            <Route exact path="/short-story" element={<ShortStoryList/>}/>
            <Route exact path="/poem" element={<PoemList/>}/>
            <Route exact path="/book-review" element={<BookReviewList/>}/>
            <Route exact path="/opinion-article" element={<OpinionArticleList/>}/>
            <Route exact path="/author-feature" element={<AuthorFeatureList/>}/>
            <Route exact path="/cartoon" element={<CartoonList/>}/>
            <Route exact path="/comic" element={<ComicList/>}/>
            <Route exact path="/joke" element={<JokeList/>}/>
            <Route exact path="/meme" element={<MemeList/>}/>
            <Route exact path="/puzzle" element={<PuzzleList/>}/>
            <Route exact path="/crossword" element={<CrosswordList/>}/>


            <Route exact path="/novella/:slug" element={<NovellaDetail/>}/>
            <Route exact path="/serial/:slug" element={<SerialDetail/>}/>
            <Route exact path="/drama/:slug" element={<DramaDetail/>}/>
            <Route exact path="/short-story/:slug" element={<ShortStoryDetail/>}/>
            <Route exact path="/poem/:slug" element={<PoemDetail/>}/>
            <Route exact path="/book-review/:slug" element={<BookReviewDetail/>}/>
            <Route exact path="/opinion-article/:slug" element={<OpinionArticleDetail/>}/>
            <Route exact path="/author-feature/:slug" element={<AuthorFeatureDetail/>}/>
            <Route exact path="/cartoon/:slug" element={<CartoonDetail/>}/>
            <Route exact path="/comic/:slug" element={<ComicDetail/>}/>
            <Route exact path="/joke/:slug" element={<JokeDetail/>}/>
            <Route exact path="/meme/:slug" element={<MemeDetail/>}/>
            <Route exact path="/puzzle/:slug" element={<PuzzleDetail/>}/>
            <Route exact path="/crossword/:slug" element={<CrosswordDetail/>}/>

            <Route path="*" element={<NotFound/>}/>
          </Routes>
          <Footer />
        </Box>
      </ThemeProvider>
    </Router>
  );
}

export default App;
