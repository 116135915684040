import React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { Link } from 'react-router-dom';

const PoetryHighlights = (content) => {
  const data = content.data;
  return data && (
    <>
      <Typography variant="genreMain" style={{ marginBottom: 24 }}>Poetry</Typography>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        spacing={4}
      >
        {data.map((item, index) => (
          <>
            <Grid item xs={6} key={index}>
              <Typography variant="h2" style={{ fontStyle: 'italic' }}>{item.title}</Typography>
              <Typography variant="summary" style={{ marginTop: 8 }}>{item.summary}</Typography>
              <Link to={`/${item.subgenre}/${item.slug}`} style={{ textDecoration: 'none' }}>
                <Typography variant="extraInfo"
                  sx={{
                    '&:hover': {
                      textDecoration: 'underline',
                    }
                  }}
                >
                  Read More
                </Typography>
              </Link>
              <br />
              <div style={{ marginTop: 16 }}>
                {item.is_translated &&
                  <Typography variant="authorBold">
                    Translated by {item.author}
                  </Typography>
                }
                {item.is_translated &&
                  <Typography variant="author">
                    <br />Written by {item.original_author}
                  </Typography>}

                {!item.is_translated &&
                  <Typography variant="authorBold">
                    Written by {item.author}
                  </Typography>
                }
              </div>
            </Grid>
          </>
        ))}
      </Grid>
    </>
  );
}

export default PoetryHighlights;
