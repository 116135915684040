import React from 'react';
import GenericListView from '../../../components/shared/GenericListView';

function FunList() {

  return (
    <div>
      <GenericListView url="/api/literature/fun" />
    </div>
  );
}

export default FunList;
