import React from 'react';
import { Paper, Typography, CardMedia, Box } from '@mui/material';
import { dateRenderer, capitalizeAndSpacify } from '../../helpers';
import DOMPurify from 'dompurify';
import { useMediaQuery } from '@mui/material';
import theme from './Theme';

const removeInlineStyles = (html) => {
  const doc = new DOMParser().parseFromString(html, 'text/html');
  Array.from(doc.body.querySelectorAll('*')).forEach((el) => {
    if (el.style.fontSize) {
      el.style.removeProperty('font-size');
    }
    if (el.style.fontFamily) {
      el.style.removeProperty('font-family');
    }
  });
  return doc.body.innerHTML;
};

const SafeHTMLContent = ({ html }) => {
  let sanitizedHTML = DOMPurify.sanitize(html);
  sanitizedHTML = removeInlineStyles(sanitizedHTML);
  return <Typography variant="body1" paragraph>
    <div
      dangerouslySetInnerHTML={{ __html: sanitizedHTML }}
    />
  </Typography>;
};

const TextContentDetailView = ({ data }) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    data &&
    // width is min of 1024px and 100%
    <div style={{ width: "100%", maxWidth: "896px", margin: "16px auto" }}>
      {data.image &&
        <CardMedia
          component="img"
          image={data.image}
          alt="Content Image"
          sx={{
            width: '100%', maxHeight: '400px', objectFit: 'cover', cursor: "pointer",
            "&:hover": {
              boxShadow: "0px 0px 20px 0px rgba(0,0,0,0.9)",
              transition: "box-shadow 0.2s ease-in-out",
            },
          }}
          onClick={(x) => {
            if (x.target.style.maxHeight === "none") {
              x.target.style.maxHeight = "400px";
            }
            else {
              x.target.style.maxHeight = "none";
            }
          }}
        />
      }
      <Box style={{ backgroundColor: "#f6f6f6", padding: isMobile ? "20px 20px" : "48px 48px" }}>
        <Typography variant="genreBold">
          {capitalizeAndSpacify(data.genre)}
        </Typography>
        <Typography variant="genre">
          <span> </span>| {capitalizeAndSpacify(data.subgenre)}
        </Typography>
        <Typography variant="h1" style={{ paddingTop: 8 }}>
          {data.title}
        </Typography>
        {data.is_translated &&
          <Typography variant="authorBold">
            Translated by {data.author}
          </Typography>
        }
        {data.is_translated &&
          <Typography variant="author">
            <span> </span> | Written by {data.original_author}
          </Typography>}

        {!data.is_translated &&
          <Typography variant="authorBold">
            Written by {data.author}
          </Typography>
        }
        <br />
        <Typography variant="extraInfo">
          {dateRenderer(data.created_at)}
        </Typography>
      </Box>
      <Paper elevation={0} style={{ padding: isMobile ? "8px 16px" : "16px 16px" }}>
        <Typography variant="body1" paragraph sx={{ marginTop: 2 }}>
          {/* Render the content using SafeHTMLContent */}
          <SafeHTMLContent html={data.content} />
        </Typography>
      </Paper>
    </div>
  );
};

export default TextContentDetailView;
