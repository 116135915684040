import React from 'react';
import GenericListView from '../../../components/shared/GenericListView';

function NovellaList() {

  return (
    <div>
      <GenericListView url="/api/literature/novella" />
    </div>
  );
}

export default NovellaList;
