import React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import CardMedia from '@mui/material/CardMedia';
import { capitalizeAndSpacify } from '../../helpers';
import { Link } from 'react-router-dom';

const VisualHighlights = (content) => {
  const item = content.data[0];
  return item && (
    <>
      <Typography variant="genreMain" style={{ marginBottom: 32 }}>Visual</Typography>
      <Box sx={{ overflow: 'hidden' }}>
        <CardMedia
          component="img"
          image={item.image}
          alt="placeholder"
          sx={{ width: '100%', height: '200px', objectFit: 'cover', objectPosition: 'center' }}
        />
      </Box>
      <Link to={`/${item.subgenre}/${item.slug}`} style={{ textDecoration: 'none', color: 'black' }}>
      <Typography variant="h2" style={{marginTop: 16}}
        sx={{
          '&:hover': {
            textDecoration: 'underline',
          }
        }}
      >{item.title}</Typography>
      </Link>
      <Typography variant="genre">
        {capitalizeAndSpacify(item.subgenre)}
      </Typography>
      <div style={{ marginTop: 16 }}>
        {item.is_translated &&
          <Typography variant="authorBold">
            Translated by {item.author}
          </Typography>
        }
        {item.is_translated &&
          <Typography variant="author">
            <br />Written by {item.original_author}
          </Typography>}

        {!item.is_translated &&
          <Typography variant="authorBold">
            Written by {item.author}
          </Typography>
        }
      </div>
      <br />
    </>
  );
}

export default VisualHighlights;
