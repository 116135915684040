import React from 'react';
import GenericListView from '../../../components/shared/GenericListView';

function MemeList() {

  return (
    <div>
      <GenericListView url="/api/literature/meme" />
    </div>
  );
}

export default MemeList;
