import TextContentDetailView from "./TextContentDetailView";
import { fetchData } from "../../helpers";
import React from "react";
import { useEffect, useState } from "react";
import PdfContentDetailView from "./PdfContentDetailView";

const GenericDetailView = ({ url }) => {

    const [data, setData] = useState(null);

    useEffect(() => {
        fetchData(url).then((contents) => {
            setData(contents);
        }
      )}, [url]);

    return (
        data && (
            data.genre === 'visual' || (data.genre === 'fun' && data.subgenre !== 'joke')  ?
        <PdfContentDetailView data={data} />
            :
        <TextContentDetailView data={data} />
        )
    )
}

export default GenericDetailView;
