import React from 'react';
import { Paper, Typography, CardMedia, Box } from '@mui/material';
import { dateRenderer, capitalizeAndSpacify } from '../../helpers';
import { useMediaQuery } from '@mui/material';
import theme  from './Theme';
import MyPdfViewer from './MyPdfViewer';

const PdfContentDetailView = ({ data }) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    data &&
    <>
      {data.image &&
      <CardMedia
        component="img"
        image={data.image}
        alt="Content Image"
        sx={{ width: '100%', maxHeight: '500px', objectFit: 'cover' }}
      />
      }
      <Box style={{ backgroundColor: "#f6f6f6", padding: isMobile ? "20px 20px" : "56px 64px" }}>
        <Typography variant="genreBold">
          {capitalizeAndSpacify(data.genre)}
        </Typography>
        <Typography variant="genre">
          <span> </span>| {capitalizeAndSpacify(data.subgenre)}
        </Typography>
        <Typography variant="h1" style={{ paddingTop: 8 }}>
          {data.title}
        </Typography>
        {data.is_translated &&
          <Typography variant="authorBold">
            Translated by {data.author}
          </Typography>
        }
        {data.is_translated &&
          <Typography variant="author">
            <span> </span> | Written by {data.original_author}
          </Typography>}

        {!data.is_translated &&
          <Typography variant="authorBold">
            Written by {data.author}
          </Typography>
        }
        <br />
        <Typography variant="extraInfo">
          {dateRenderer(data.created_at)}
        </Typography>
      </Box>
      <Paper elevation={0} style={{ padding: isMobile ? "8px 16px" : "24px 32px" }}>
        <MyPdfViewer pdf_url={data.content} />
      </Paper>
    </>
  );
};

export default PdfContentDetailView;
