import axios from 'axios';
import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import useMediaQuery from '@mui/material/useMediaQuery';
import useTheme from '@mui/material/styles/useTheme';

import AllHighlights from '../components/home/AllHighlights';
import TheLatest from '../components/home/TheLatest';
import FictionHighlights from '../components/home/FictionHighlights';
import PoetryHighlights from '../components/home/PoetryHighlights';
import AnalysisHighlights from '../components/home/AnalysisHighlights';
import VisualHighlights from '../components/home/VisualHighlights';
import FunHighlights from '../components/home/FunHighlights';

function Home() {

  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('/api/literature/highlight');
        const contentType = response.headers['content-type'];
        if (contentType && contentType.includes('application/json')) {
          setData(response.data);
        } else {
          // If not JSON, handle the unexpected content-type
          throw new Error('Invalid content-type. Expected application/json');
        }
      } catch (errorMsg) {
        console.log(errorMsg);
      }
    };

    fetchData();
  }, []); // Empty array ensures the effect runs only once

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    ( data  &&
    <>
      <AllHighlights data={data['top']}/>
      <Paper elevation={0} style={{
        padding: isMobile ? '12px 16px' : '24px 60px'
      }}>
        <TheLatest data={data['latest']} />
        <FictionHighlights data={data['fiction']} />
        <Grid container spacing={4} style={{marginTop: 8}}>
          <Grid item xs={12} md={6}>
            <PoetryHighlights data={data['poetry']} />
          </Grid>
          <Grid item xs={12} md={6}>
            <VisualHighlights data={data['visual']} />
          </Grid>
        </Grid>
        <AnalysisHighlights data={data['analysis']} />
        {/* TODO: Activate when ready to be served */}
        {/* <FunHighlights data={data} /> */}
      </Paper>
    </>
    )
  );
}

export default Home;
