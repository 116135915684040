import React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import BookReviewHighlights from './analysis/BookReviewHighlights';
import AuthorFeatureHighlights from './analysis/AuthorFeatureHighlights';
import OpinionHighlights from './analysis/OpinionHighlights';
import Box from '@mui/material/Box';
import CardMedia from '@mui/material/CardMedia';
import { capitalizeAndSpacify } from '../../helpers';
import { Link } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import useTheme from '@mui/material/styles/useTheme';

const AnalysisHighlights = (content) => {
  const leftItem = content.data[0];
  const rightData = content.data.slice(1, 3);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return leftItem && (
    <div style={{ marginTop: 32 }}>
      <Typography variant="genreMain">Analysis</Typography>
      <div style={{ marginTop: 24, backgroundColor: '#F9F7EE', padding: isMobile ? '8px 8px' : '20px 20px' }}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            <Box sx={{ overflow: 'hidden' }}>
              <CardMedia
                component="img"
                image={leftItem.image}
                alt={leftItem.title}
                sx={{ width: '100%', height: isMobile ? '300px' : '100%', objectFit: 'cover', objectPosition: 'center' }}
              />
            </Box>
            <Link to={`/${leftItem.subgenre}/${leftItem.slug}`} style={{ textDecoration: 'none', color: 'black' }}>
              <Typography variant="headerLit" align="center" sx={{
                '&:hover': {
                  textDecoration: 'underline',
                }
              }}>{leftItem.title.toUpperCase()}</Typography>
            </Link>
            <div style={{ textAlign: 'center', marginTop: -8 }}>
              <Typography variant="genre">
                {capitalizeAndSpacify(leftItem.subgenre)}
              </Typography>
              <br />
              <div style={{ marginTop: 8 }}>
                {leftItem.is_translated &&
                  <Typography variant="authorBold">
                    Translated by {leftItem.author}
                  </Typography>
                }
                {leftItem.is_translated &&
                  <Typography variant="author">
                    <br />Written by {leftItem.original_author}
                  </Typography>}

                {!leftItem.is_translated &&
                  <Typography variant="authorBold">
                    Written by {leftItem.author}
                  </Typography>
                }
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={8}>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              spacing={4}
            >
              {rightData.map((item, index) => (
                <>
                  <Grid item xs={12} md={4} key={index}>
                    <Box sx={{ overflow: 'hidden' }}>
                      <CardMedia
                        component="img"
                        image={item.image}
                        alt={item.title}
                        sx={{ width: '100%', height: isMobile ? '300px' : '200px', objectFit: 'cover', objectPosition: 'center' }}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={8} key={index}>
                    <Typography variant="h2"
                      style = {{ marginTop: isMobile ? -16 : 0 }}
                    >{item.title}</Typography>
                    <Typography variant="genre">
                      {capitalizeAndSpacify(item.subgenre)}
                    </Typography>
                    <Typography variant="summary" style={{ marginTop: 16 }}>{item.summary}</Typography>
                    <Link to={`/${item.subgenre}/${item.slug}`} style={{ textDecoration: 'none' }}>
                      <Typography variant="extraInfo"
                        sx={{
                          '&:hover': {
                            textDecoration: 'underline',
                          }
                        }}
                      >
                        Read More
                      </Typography>
                    </Link>
                    <br />
                    <div style={{ marginTop: 16 }}>
                      {item.is_translated &&
                        <Typography variant="authorBold">
                          Translated by {item.author}
                        </Typography>
                      }
                      {item.is_translated &&
                        <Typography variant="author">
                          <br />Written by {item.original_author}
                        </Typography>}

                      {!item.is_translated &&
                        <Typography variant="authorBold">
                          Written by {item.author}
                        </Typography>
                      }
                    </div>
                  </Grid>
                </>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default AnalysisHighlights;
