import React from 'react';

function News() {
  return (
    <div>
      Hello this is News page
    </div>
  );
}

export default News;
