import React from 'react';
import GenericListView from '../../../components/shared/GenericListView';

function ShortStoryList() {

  return (
    <div>
      <GenericListView url="/api/literature/short-story" />
    </div>
  );
}

export default ShortStoryList;
