import React from 'react';
import GenericListView from '../../../components/shared/GenericListView';

function SerialList() {

  return (
    <div>
      <GenericListView url="/api/literature/serial" />
    </div>
  );
}

export default SerialList;
