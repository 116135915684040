import React from 'react';
import { useParams } from 'react-router-dom';
import GenericDetailView from '../../../components/shared/GenericDetailView';

function MemeDetail() {

    const { slug } = useParams();
        return (
            <GenericDetailView url={`/api/literature/meme/${slug}`} />
        );
}

export default MemeDetail;
