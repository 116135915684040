import React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import CardMedia from '@mui/material/CardMedia';
import { capitalizeAndSpacify } from '../../helpers';
import { Link } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import useTheme from '@mui/material/styles/useTheme';


const TheLatest = (content) => {
  const data = content.data;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return data && (
    <div style={{ marginTop: 32 }}>
      <Typography variant="genreMain" style={{ marginBottom: 20 }}>The Latest</Typography>
      <Grid
        container
        justifyContent="center"
        spacing={4}
      >
        {data.map((item, index) => (
          <Grid item xs={12} md={3} key={index}>
            <Box sx={{ overflow: 'hidden' }}>
              <CardMedia
                component="img"
                image={item.image}
                alt={item.title}
                sx={{ width: '100%', height: isMobile ? '300px' : '250px', objectFit: 'cover', objectPosition: 'center' }}
              />
            </Box>
            <Link to={`/${item.subgenre}/${item.slug}`} style={{ textDecoration: 'none', color: 'black' }}>
              <Typography variant="headerLit" align="center" sx={{
                '&:hover': {
                  textDecoration: 'underline',
                }
              }}>{item.title.toUpperCase()}</Typography>
            </Link>
            <div style={{ textAlign: 'center' }}>
              <Typography variant="genreBold">
                {capitalizeAndSpacify(item.genre)}
              </Typography>
              <Typography variant="genre">
                <span> </span>| {capitalizeAndSpacify(item.subgenre)}
              </Typography>
              <br />
              <div style={{ marginTop: 8 }}>
                {item.is_translated &&
                  <Typography variant="authorBold">
                    Translated by {item.author}
                  </Typography>
                }
                {item.is_translated &&
                  <Typography variant="author">
                    <br />Written by {item.original_author}
                  </Typography>}

                {!item.is_translated &&
                  <Typography variant="authorBold">
                    Written by {item.author}
                  </Typography>
                }
              </div>
            </div>
          </Grid>
        ))}
      </Grid>
    </div>
  );
}

export default TheLatest;
