import React from 'react';
import GenericListView from '../../../components/shared/GenericListView';

function JokeList() {

  return (
    <div>
      <GenericListView url="/api/literature/joke" />
    </div>
  );
}

export default JokeList;
