import React from 'react';

function Contact() {
  return (
    <div>
      Hello this is Contact page
    </div>
  );
}

export default Contact;
