import React from 'react';
import GenericListView from '../../../components/shared/GenericListView';

function AuthorFeatureList() {

  return (
    <div>
      <GenericListView url="/api/literature/author-feature" />
    </div>
  );
}

export default AuthorFeatureList;
