import React from 'react';
import { useParams } from 'react-router-dom';
import GenericDetailView from '../../../components/shared/GenericDetailView';

function ShortStoryDetail() {

    const { slug } = useParams();
        return (
            <GenericDetailView url={`/api/literature/short-story/${slug}`} />
        );
}

export default ShortStoryDetail;
