import React from 'react';

function NotFound() {
  return (
    <div>
      Hello this is Not Found page
    </div>
  );
}

export default NotFound;
