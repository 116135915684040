import React from 'react';
import GenericListView from '../../../components/shared/GenericListView';

function ComicList() {

  return (
    <div>
      <GenericListView url="/api/literature/comic" />
    </div>
  );
}

export default ComicList;
