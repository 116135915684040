import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const MyPdfViewer = ({ pdf_url }) => {
    const [numPages, setNumPages] = useState(null);

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    // Inline styles for the PDF viewer container
    const pdfViewerStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '16px',
        backgroundColor: '#ffffff', // Use a neutral background color
        boxShadow: '0 2px 8px rgba(0,0,0,0.1)', // Subtle shadow for depth
        margin: '16px 0', // Spacing above and below the viewer
    };

    // Inline styles for each page
    const pageStyle = {
        display: 'flex',
        justifyContent: 'center',
        margin: '0 auto', // Center the page horizontally
        boxShadow: '0 2px 5px rgba(0,0,0,0.1)', // Subtle shadow for each page
        maxWidth: '100%', // Ensure the page is not wider than its container
    };

    return (
        <div style={pdfViewerStyle}>
            <OpenInNewIcon
                fontSize={window.innerWidth > 768 ? "large" : "small"}
                sx={{
                    '&:hover': {
                        boxShadow: '0 2px 8px rgba(0,0,0,0.3)',
                        cursor: 'pointer',
                    },
                    'alignSelf': 'flex-end',
                }}
                onClick={() => window.open(pdf_url, '_blank')}
            />
            <Document
                file={pdf_url}
                onLoadSuccess={onDocumentLoadSuccess}
                renderMode="canvas" // Ensures the PDF is rendered using <canvas> elements
            >
                {Array.from(new Array(numPages), (el, index) => (
                    <Page
                        key={`page_${index + 1}`}
                        pageNumber={index + 1}
                        width={window.innerWidth > 768 ? (window.innerWidth > 1200 ? 1000 : window.innerWidth * 0.9) : window.innerWidth - 32} // Responsive width based on the screen size
                        renderAnnotationLayer={false} // Disables rendering of annotations (which includes text layer)
                        renderTextLayer={false} // Disables text layer rendering
                        style={pageStyle}
                    />
                ))}
            </Document>
        </div>
    );
};

export default MyPdfViewer;
