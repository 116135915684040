import React from 'react';
import GenericListView from '../../../components/shared/GenericListView';

function BookReviewList() {

  return (
    <div>
      <GenericListView url="/api/literature/book-review" />
    </div>
  );
}

export default BookReviewList;
