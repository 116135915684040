import React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import CardMedia from '@mui/material/CardMedia';
import Paper from '@mui/material/Paper';
import { capitalizeAndSpacify } from '../../helpers';
import { Link } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import useTheme from '@mui/material/styles/useTheme';

const FictionHighlights = (content) => {
  const data = content.data;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return data && (
    <div style={{ marginTop: 48}}>
      <Typography variant="genreMain">Fiction</Typography>
      <Paper elevation={0} style={{ backgroundColor: '#eef3f3',  padding: isMobile ? '8px 8px' : '20px 20px' }}>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          spacing={4}
        >
          {data.map((item, index) => (
            <>
              <Grid item xs={12} md={4} key={index}>
                <Box sx={{ overflow: 'hidden' }}>
                  <CardMedia
                    component="img"
                    image={item.image}
                    alt={item.title}
                    sx={{ width: '100%', height: isMobile ? '300px' : '200px', objectFit: 'cover', objectPosition: 'center' }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={8} key={index}>
                <Typography variant="h2"
                  style = {{ marginTop: isMobile ? -16 : 0 }}
                >{item.title}</Typography>

                <Typography variant="genre">
                  {capitalizeAndSpacify(item.subgenre)}
                </Typography>
                <br />
                <Typography variant="summary" style={{ marginTop: 16}}>{item.summary}</Typography>
                <Link to={`/${item.subgenre}/${item.slug}`} style={{ textDecoration: 'none' }}>
                  <Typography variant="extraInfo"
                    sx={{
                      '&:hover': {
                        textDecoration: 'underline',
                      }
                    }}
                  >
                    Read More
                  </Typography>
                </Link>
                <br />
                <div style={{ marginTop: 16 }}>
                {item.is_translated &&
                  <Typography variant="authorBold">
                    Translated by {item.author}
                  </Typography>
                }
                {item.is_translated &&
                  <Typography variant="author">
                    <br />Written by {item.original_author}
                  </Typography>}

                {!item.is_translated &&
                  <Typography variant="authorBold">
                    Written by {item.author}
                  </Typography>
                }
                </div>
              </Grid>
            </>
          ))}
        </Grid>
      </Paper>
    </div>
  );
}

export default FictionHighlights;
