import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import logo from '../../assets/images/logo.png';

const allLinks = [

    {
        name: 'Fiction',
        link: '/fiction',
    },
    {
        name: 'Poetry',
        link: '/poetry',
    },
    {
        name: 'Analysis',
        link: '/analysis',
    },
    {
        name: 'Visual',
        link: '/visual',
    },
    {
        name: 'Fun',
        link: '/fun',
    },
    {
        name: 'About',
        link: '/about',
    },
    {
        name: 'News',
        link: '/news',
    },
    {
        name: 'Issues',
        link: '/issues',
    },
    {
        name: 'Contact',
        link: '/contact',
    },
];

function MobileAppBar() {
    const [drawerOpen, setDrawerOpen] = React.useState(false);

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        if(open) {
            window.scrollTo(0, 0);
            document.body.style.overflow = 'hidden';
        }
        else {
            document.body.style.overflow = 'unset';
        }
        setDrawerOpen(open);
    };

    const renderDrawerList = () => (
        <Box
            role="presentation"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
        >
            <List>
                {allLinks.map((link) => (
                    <ListItem button key={link.name} component="a" href={link.link}>
                        <ListItemText primary={link.name} />
                    </ListItem>
                ))}
            </List>
        </Box>
    );

    return (
        <AppBar position="static" style={{ overflow: 'hidden', boxShadow: 'none' }}>
            <Toolbar
                disableGutters
                style={{
                    backgroundColor: "white",
                    padding: "20px 4%",
                    display: 'flex',
                    justifyContent: 'space-between',
                }}
            >
                <a href="/"><img src={logo} alt="logo" style={{ maxWidth: "90%" }} /></a>
                <IconButton
                    size="large"
                    aria-label="menu"
                    onClick={toggleDrawer(!drawerOpen)}
                    style={{ color: 'black' }} // Directly setting the color to black
                >
                    <MenuIcon />
                </IconButton>
            </Toolbar>

            <Drawer
                zIndex={10}
                anchor='top'
                open={drawerOpen}
                onClose={toggleDrawer(false)}
                variant="persistent"
                sx={{
                    '& .MuiDrawer-paper': {
                        width: '100%',
                        marginTop: `84px`, // Push the drawer below the AppBar
                        borderTop: '1px solid rgba(0, 0, 0, 0.12)',
                    },
                }}
            >
                {renderDrawerList()}
            </Drawer>
        </AppBar>
    );
}
export default MobileAppBar;