import React from 'react';

function Issues() {
  return (
    <div>
      Hello this is Issues page
    </div>
  );
}

export default Issues;
