import {
  createTheme,
  responsiveFontSizes,
} from '@mui/material/styles';

let theme = createTheme({
  palette: {
    primary: {
      main: '#567D89',
      light: '#709F9D',
    },
    secondary: {
      main: '#DEC584',
      light: '#E2D7A7',
    },
    tertiary: {
      main: '#A16B56',
      light: '#E0A370',
    },
  },
  typography: {
    fontFamily: [
      'Inter',
      'Newsreader',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    h1: {
      fontSize: 60,
      fontFamily: 'Newsreader, sans-serif',
    },
    h2: {
      fontSize: 40,
      fontFamily: 'Newsreader, sans-serif',
    },
    headerLit: {
      fontSize: 20,
      fontFamily: 'Inter',
      fontWeight: 'bold',
      display: 'block',
      margin: '20px 0px',
    },
    author: {
      fontSize: 14,
      fontFamily: 'Inter',
      fontStyle: 'italic',
      color: '#567D89',
    },
    authorBold: {
      fontSize: 14,
      fontFamily: 'Inter',
      fontWeight: 'bold',
      fontStyle: 'italic',
      color: '#567D89',
    },
    extraInfo: {
      fontSize: 14,
      fontFamily: 'Inter',
      color: '#567D89',
    },
    genre: {
      fontSize: 16,
      fontFamily: 'Inter',
      color: '#A16B56',
    },
    genreBold: {
      fontWeight: 'bold',
      fontSize: 16,
      fontFamily: 'Inter',
      color: '#A16B56',
    },
    genreMain: {
      fontWeight: 'bold',
      fontSize: 24,
      fontFamily: 'Inter',
      color: '#567D89',
      textTransform: 'uppercase',
      display: 'block',
      margin: '20px 0px',
    },
    summary: {
      fontStyle: 'italic',
      fontSize: 16,
      fontFamily: 'Newsreader, sans-serif',
      display: 'block',
      whiteSpace: 'pre-line',
    },
    bookRevTitle: {
      fontWeight: 'bold',
      fontSize: 40,
      fontFamily: 'Newsreader, sans-serif',
    },
    bookRevTitleSmall: {
      fontWeight: 'bold',
      fontSize: 25,
      fontFamily: 'Newsreader, sans-serif',
    },
    bookRevSummary: {
      fontSize: 20,
      fontFamily: 'Newsreader, sans-serif',
    },
    // test
    small: {
      fontSize: 16,
      fontFamily: 'Inter',
      color: 'black',
    },
    body1: {
      fontSize: 20,
      fontFamily: 'Inter',
      // whiteSpace: 'pre-line',
    },
    body2: {
      fontSize: 20,
      fontFamily: 'Helvetica',
      // whiteSpace: 'pre-line',
    },
  },
  spacing: [0, 4, 8, 16, 32, 64],
});
theme = responsiveFontSizes(theme);

export default theme;
