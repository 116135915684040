import axios from 'axios';

const fetchData = async (url) => {
    try {
        const response = await axios.get(url);
        const contents = response.data;
        return contents;
    } catch (errorMsg) {
      console.log(errorMsg);
    }
};

const flattenContents = (contents) => {
    if (Array.isArray(contents)) {
        return contents;
    } else {
        return Object.values(contents).flatMap(flattenContents);
    }
};

const sortByCreatedDate = (contents) => {
    return contents.sort(
        (a, b) => new Date(a.created_at) - new Date(b.created_at)
    );
}

const getTodaysIndex = (n) => {
    // get today's date
    const today = new Date();
    // convert to string (day, monht, year)
    const dateString = today.toDateString();
    // hash the string
    let hash = 0;
    for (let i = 0; i < dateString.length; i++) {
        const char = dateString.charCodeAt(i);
        hash = (hash << 5) - hash + char;
        hash |= 0; // Convert to 32bit integer
    }   
    // get the index
    const index = hash % n;
    if(index < 0) {
        return index + n;
    }
    return index;
}

const capitalizeAndSpacify = (str) => {
    if(typeof str !== 'string') return ''
    // split by '-'
    const words = str.split('-');
    // capitalize each word
    const capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));
    // join words with space
    const capitalizedAndSpacified = capitalizedWords.join(' ');
    return capitalizedAndSpacified;
}

const dateRenderer = (dateString) => {
    // takes something like this:  2023-11-20T18:05:00.336275Z
    // returns something like this: 20 Nov 2023
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'short' });
    const year = date.getFullYear();
    const formattedDate = `${day} ${month}. ${year}`;
    return formattedDate;
}

export { fetchData, flattenContents, sortByCreatedDate, getTodaysIndex, capitalizeAndSpacify, dateRenderer };   