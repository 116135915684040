import React from 'react';
import {
  useTheme,
  useMediaQuery,
  Box,
  Card,
  CardMedia,
  CardContent,
  Typography,
  Grid,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { capitalizeAndSpacify } from '../../helpers';
import { useState, useEffect } from 'react';

function ArticleListView({ data, contentType }) {

  const [forceRender, setForceRender] = useState(0); // State to trigger re-render

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));

  useEffect(() => {
    // When isMobile changes, update the state to trigger a re-render
    setForceRender(prev => prev + 1);
  }, [isMobile]);

  return (
    <Box sx={{ width: '100%', overflow: 'hidden' }}>
      <Grid container spacing={2} sx={{ padding: 4 }}>
        <Typography variant="headerLit">
          List of {contentType}
        </Typography>
        {data.map((item, index) => (
          <Grid item xs={12} key={index}
          >
            <Link to={`/${item.subgenre}/${item.slug}`} style={{ textDecoration: 'none' }}>
              <Card style={{ marginBottom: 16 }}
                sx={{ '&:hover': { boxShadow: 8 } }}>
                <Grid
                  container
                  spacing={2}
                  direction={isMobile ? 'column' : 'row'}
                  wrap="nowrap"
                  alignItems={isMobile ? "flex-start" : "center"}
                  justifyContent={isMobile ? "flex-start" : "center"}
                >
                  <Grid item xs={12} sm={5} md={4} lg={3} alignSelf={'center'}>
                    <Box sx={{ width: '100%', 
                    height: isMobile && !item.image ? '0px' : '300px', 
                    overflow: 'hidden', backgroundColor: '#f6f6f6' }}>
                      {item.image &&
                        <CardMedia
                          component="img"
                          image={item.image}
                          alt={item.title}
                          loading="lazy"
                          sx={{ width: '100%', height: '100%', objectFit: 'cover', objectPosition: 'center' }}
                        />
                      }
                    </Box>
                  </Grid>
                  <Grid item xs>
                    <CardContent>
                      <Typography variant="genreBold">{capitalizeAndSpacify(item.genre)}</Typography>
                      <Typography variant="genre"> | {capitalizeAndSpacify(item.subgenre)}</Typography>
                      <Typography variant="h2" style={{ padding: "8px 0px" }}>
                        {item.title}
                      </Typography>
                      <Typography variant="summary" style={{ paddingBottom: 16 }}>
                        {item.summary}
                      </Typography>
                      {item.is_translated &&
                        <Typography variant="authorBold">
                          Translated by {item.author}
                        </Typography>
                      }
                      {item.is_translated &&
                        <Typography variant="author">
                          <br />Written by {item.original_author}
                        </Typography>}

                      {!item.is_translated &&
                        <Typography variant="authorBold">
                          Written by {item.author}
                        </Typography>
                      }
                    </CardContent>
                  </Grid>
                </Grid>
              </Card>
            </Link>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}


export default ArticleListView;
