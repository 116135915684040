import React from 'react';
import GenericListView from '../../../components/shared/GenericListView';

function DramaList() {

  return (
    <div>
      <GenericListView url="/api/literature/drama" />
    </div>
  );
}

export default DramaList;
